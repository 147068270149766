export default {
  "actionsSelector": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстрые клавиши"])}
  },
  "activeTable": {
    "action": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстрые клавиши"])},
      "deselectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снять выделение"])},
      "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки отображения"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
      "resetFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить фильтр"])},
      "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать всё"])}
    },
    "filters": {
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])}
    },
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все"])},
    "summaries": {
      "chunks": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Загрузка фрагментов: ", _interpolate(_named("current")), " из ", _interpolate(_named("total"))])},
      "selected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Выбрано ", _interpolate(_named("selected")), " из ", _interpolate(_named("total"))])},
      "shown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показано ", _interpolate(_named("shown")), " из ", _interpolate(_named("total")), " (с ", _interpolate(_named("from")), " по ", _interpolate(_named("to")), ")"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого:"])}
  },
  "admin": {
    "billing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка биллинга"])}
    },
    "courts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подсудности"])}
    },
    "licenses": {
      "actions": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
        "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичные данные"])},
        "recreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пересоздать"])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
        "secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный ключ"])}
      },
      "buttons": {
        "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
      },
      "fields": {
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создана в"])},
        "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные"])},
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие лицензии в днях"])},
        "expired_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истекает в"])},
        "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активна"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])},
        "public_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичный ключ"])},
        "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центр лицензий"])}
    },
    "recognize": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разметка ИИ"])}
    },
    "standartize": {
      "filters": {
        "cadnum_quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качество кад. номера"])},
        "cadnum_sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник кад. номера"])},
        "is_setted__cadnum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наличие кадастрового номера"])},
        "is_setted__changed_mir_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мировой суд изменен"])},
        "is_setted__changed_reg_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Районный суд изменен"])},
        "is_setted__flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Квартира заполнена"])},
        "is_setted__flat_cadnum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наличие кадастрового номера квартиры"])},
        "is_setted__flat_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Существование квартиры"])},
        "is_setted__fssp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФССП заполнено"])},
        "is_setted__house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дом заполнен"])},
        "is_setted__main_cadnum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наличие кадастрового номера"])},
        "is_setted__mir_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мировой суд заполнен"])},
        "is_setted__postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый индекс заполнен"])},
        "is_setted__reg_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Районный суд заполнен"])},
        "is_setted__result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес заполнен"])},
        "is_setted__street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица заполнена"])},
        "is_verified__fssp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФССП подтверждено"])},
        "is_verified__main_cadnum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кадастровый номер подтвержден"])},
        "is_verified__mir_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мировой суд подтвержден"])},
        "is_verified__reg_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Районный суд подтвержден"])},
        "is_verified__result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес подтвержден"])},
        "is_verified__similar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартизирован по схожему адресу"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стандартизация"])}
    },
    "statistic": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])}
    },
    "templates": {
      "templateModes": {
        "json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JSON"])},
        "linear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линейный"])},
        "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Табличный"])}
      },
      "templateTypes": {
        "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конструктор реестра загрузки"])}
    },
    "upload_enrichment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка обогащения"])}
    },
    "upload_regions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка регионов"])}
    }
  },
  "archiveDebtors": {
    "dialog": {
      "confirmLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите действие"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите отправить выбранных должников в архив"])}
    },
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для архивирования"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивировать должников"])},
    "toast": {
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка архивирования данных"])},
      "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивирование данных..."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должники успешно отправлены в архив"])}
    }
  },
  "attachEmployee": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите сотрудника из списка"])},
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для закрепления за ним сотрудника"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепить"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепление сотрудников к должникам"])}
  },
  "captcha": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка проверки на робота"])}
  },
  "companies": {
    "add": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
      "inn": {
        "checking": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка существования ИНН"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка ИНН"])}
        },
        "invalid": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите верный ИНН"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН не обнаружен"])}
        },
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ИНН новой компании"])},
        "valid": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данный ИНН может быть использован"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН верный!"])}
        }
      },
      "kpp": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите КПП филиала"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН организации"])}
    },
    "table": {
      "action": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить организацию"])},
        "remove": {
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы уверены, что хотите удалить организацию \"", _interpolate(_named("name")), "\"?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление организации"])}
        },
        "setDefault": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить по умолчанию"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы уверены, что хотите установить организацию\n \"", _interpolate(_named("name")), "\"\n по умолчанию?"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смена организации по умолчанию"])}
        }
      },
      "column": {
        "inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
        "kpp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КПП"])},
        "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридический адрес"])},
        "name_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное наименование"])},
        "name_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сокращенное\n наименование"])}
      },
      "filter": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите текст"])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организации"])}
  },
  "company": {
    "common": {
      "activity": {
        "accrual_by_area": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет задолженности по площади"])}
        },
        "company_services": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставляемые услуги"])}
        },
        "day_accrual_by_area": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День расчета задолженности по площади"])}
        },
        "base_type": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основание для взыскания задолженности"])}
        },
        "module": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип деятельности"])}
        },
        "okopf": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОКОПФ"])}
        },
        "penalty_key_rate_mode": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процентная ставка по умолчанию"])}
        },
        "penalty_percentage": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процентная ставка"])}
        },
        "taxation_system": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система налогообложения"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деятельность"])},
        "type": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип организации"])}
        },
        "type_peni_calculation": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип расчета пени"])}
        }
      },
      "addresses": {
        "legal_address": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридический адрес"])}
        },
        "physical_address": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактический адрес"])}
        },
        "postal_address_zip_code": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый адрес"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])}
      },
      "asLegalAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как юр. адрес"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
      "contacts": {
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail приемной"])}
        },
        "email_buh": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail бухгалтерии"])}
        },
        "email_cor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail для корреспонденции"])}
        },
        "fax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Факс"])}
        },
        "phone": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])}
        },
        "site": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайт"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])}
      },
      "direction": {
        "director": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генеральный директор"])}
        },
        "representative_power_attorney": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Представитель по доверенности"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дирекция"])}
      },
      "name": {
        "name_branch": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филиал"])}
        },
        "name_full": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное наименование"])}
        },
        "name_short": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сокращенное наименование"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])}
      },
      "requisites": {
        "bic": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИК"])}
        },
        "full_name_bank": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное наименование банка"])}
        },
        "inn": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])}
        },
        "kor_schet": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондентский счет"])}
        },
        "kpp": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КПП"])}
        },
        "ogrn": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОГРН"])}
        },
        "ras_schet": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчетный счет"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая информация"])}
    },
    "documents": {
      "noDocumentSigFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите электронные подписи с документам"])},
      "noSignerFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите доверенность"])},
      "noSignerSigFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите электронную подпись с каждой доверенности"])},
      "noSignerUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пользователей для каждой из доверенностей"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "signer": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата составления"])},
        "date_exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок полномочий"])},
        "signer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписант"])},
        "signer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО подписанта"])},
        "userPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пользователей доверенности"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы"])},
      "toasts": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка обновления документов"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление..."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы обновлены"])}
      }
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим редактирования"])},
    "employees": {
      "action": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить сотрудника"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить этого сотрудника?"])}
      },
      "column": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должность"])},
        "user_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])}
      },
      "remove": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка удаления сотрудника"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сотрудники"])}
    }
  },
  "components": {
    "datepicker": {
      "shortcuts": {
        "last3Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 месяца"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
        "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])}
      }
    },
    "toast": {
      "remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Осталось ", _interpolate(_named("remaining"))])}
    }
  },
  "debtor": {
    "automatizing": {
      "autoFilingClaim": {
        "automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическая подача судебного приказа\\иска в суд (с использованием ЭЦП)"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вручную"])}
      },
      "failedAttempts": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не отправлять повторно"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 попытка"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 попытки"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 попытки"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 попытки"])}
      },
      "field": {
        "auto_create_sms_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизация включена"])},
        "auto_create_voice_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизация включена"])},
        "auto_create_email_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизация включена"])},
        "voice_notifications_by_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период звонка"])},
        "voice_weekday_period_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с"])},
        "voice_weekday_period_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
        "voice_weekend_or_holiday_period_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с"])},
        "voice_weekend_or_holiday_period_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
        "voice_timezone_by_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учет часового пояса по адресу объекта"])},
        "voice_auto_exclude_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически исключать номера телефонов из обзвона"])},
        "voice_excluding_all_numbers_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статусы для исключения всех номеров телефона из обзвона"])},
        "voice_excluding_current_numbers_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статусы для исключения текущих номеров телефонов из обзвона"])},
        "voice_assigned_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответственный сотрудник"])},
        "actual_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учитывать актуальную задолженность"])},
        "debt_notifications_period_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период рассылки"])},
        "debt_notifications_period_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период рассылки"])},
        "debt_notifications_period_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период рассылки"])},
        "amount_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма для автоматической отправки или"])},
        "amount_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма для автоматической отправки/формирования судебного приказа в суд\\заказа выписки из ЕГРН\\формирования реестра оплаты пошлины"])},
        "amount_range_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задайте сумму вручную"])},
        "amount_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формирования судебного приказа в суд"])},
        "auto_attach_extracts_from_egrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое получение выписки по ранее заказанному должнику"])},
        "auto_correct_debt_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При подаче в суд учитывать период задолженности по предыдущим периодам"])},
        "auto_court_transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматический перевод должника в судебное производство"])},
        "auto_executive_transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически переводить должника в Исполнительное производство при получении судебного приказа?"])},
        "auto_fees_discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматический заказ пошлин"])},
        "auto_filing_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подача судебного приказа\\иска в суд"])},
        "auto_filing_claim_by_another_jurisdiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическая обработка возврата по другой подсудности*"])},
        "auto_filing_claim_by_no_debtor_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическая обработка возврата по отсутствию ПДн должника"])},
        "auto_filing_complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое направление заявления об ускорении рассмотрении дела по ЭЦП"])},
        "auto_filing_complaint_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время подачи"])},
        "auto_filing_isk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическая обработка отмены судебного приказа"])},
        "auto_refiling_claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизация обработки судебных решений"])},
        "auto_refiling_claims_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "auto_rosreestr_discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматический заказ выписки из ЕГРН"])},
        "auto_rosreestr_discharge_period_force": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматический перезаказ выписки из ЕГРН"])},
        "auto_update_debtor_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое обогащение данных должников"])},
        "auto_update_debtor_data_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метод поиска"])},
        "auto_update_debtor_data_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расписание"])},
        "automation_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизация включена"])},
        "claims_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма отправки претензии должнику"])},
        "complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период подачи заявления"])},
        "court_complaint_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок подачи жалобы"])},
        "court_days_overdue_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По периоду просрочки \\ По направленной претензии"])},
        "court_days_overdue_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период перевода"])},
        "court_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестовая отправка в суд"])},
        "court_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма для перевода в Судебное производство"])},
        "court_threshold_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По сумме задолженности"])},
        "court_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь, подающий в суд"])},
        "debt_notification_failed_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попыток отправки"])},
        "debt_notification_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма от"])},
        "voice_send_sms_if_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка SMS-уведомления, если занят/недоступен"])},
        "debt_notification_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма для автоматической отправки/формирования уведомлений"])},
        "debt_notifications_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизация включена"])},
        "debt_notifications_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период рассылки"])},
        "debt_notifications_period_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время рассылки"])},
        "debt_previous_rosreestr_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ выписки о переходе прав по задолженности предыдущего собственника"])},
        "debt_sms_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма для отправки SMS-уведомления должнику"])},
        "debt_voice_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма для отправки SMS-уведомления должнику"])},
        "debtors_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Число должников в диапазоне долга"])},
        "dialing_redial_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интервал времени между повторным набором номера (в часах)"])},
        "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответственные сотрудники"])},
        "enrichment_by_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По адресу"])},
        "enrichment_by_address_estates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По адресу (при наличии собственника из выписки ЕГРН)"])},
        "judicial_auto_update_debtor_data_arguments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перезапросить для уже обогащенных должников"])},
        "fees_amount_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма для автоматической оплаты госпошлин"])},
        "fees_discharge_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период автоматического формирования"])},
        "pp3_counter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядковый номер нумерации в оплате пошлины (pp3)"])},
        "fees_discharge_period_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дни отправки"])},
        "fees_discharge_period_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время отправки"])},
        "filling_claim_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расписание подачи"])},
        "filling_claim_period_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дни подачи"])},
        "filling_claim_period_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время подачи"])},
        "judicial_automation_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизация включена"])},
        "move_judicial_debtors_without_debt_to_archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическая архивация должников с нулевой задолженностью"])},
        "exclude_when_died": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить вывод умерших жителей из шаблона"])},
        "exclude_with_deregistration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить вывод снятых с регистрационного учета жителей из шаблона"])},
        "include_children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление в ЗВСП несовершеннолетних детей"])},
        "allow_without_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формировать ЗВСП в случае отсутствия подсудности"])},
        "max_dialing_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество попыток дозвона"])},
        "need_fees_discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется оплата пошлин"])},
        "need_rosreestr_discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подача в суд только с выпиской из ЕГРН"])},
        "notify_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail ответственных сотрудников"])},
        "pretrial_claim_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое формирование претензии"])},
        "pretrial_claim_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма от"])},
        "pretrial_claim_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Досудебная претензия"])},
        "pretrial_claim_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое формирование претензии"])},
        "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приоритетное уведомление"])},
        "request_recall_dialing_redial_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интервал повторного набора номера при просьбе перезвонить (в часах)"])},
        "rosreestr_amount_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма для автоматического заказа выписки ЕГРН"])},
        "rosreestr_characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка о характеристиках"])},
        "rosreestr_discharge_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период заказа выписки"])},
        "rosreestr_discharge_period_force": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период перезаказа"])},
        "rosreestr_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка о переходе прав"])},
        "send_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип подачи в суд"])},
        "unknown_owner_rosreestr_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ выписки о переходе прав, если в выписке о характеристиках нет собственников"])},
        "wait_days_before_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для юр. лиц"])},
        "without_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявитель освобожден от уплаты пошлины в соответствии со ст. 333,35, 333,36, 333,37 НК РФ"])},
        "send_electronic_court_decisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлять по Email судебные определения, полученные от суда, в электронном виде"])},
        "send_electronic_court_decisions_notify_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email для отправки судебных определений"])},
        "sync_active_court_cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизация активных судебных дел с сайта суда (офлайн подача)"])},
        "sync_archive_court_cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизация архивных судебных дел с сайта суда (офлайн подача)"])},
        "short_penalty_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод сокращенного расчета пени"])},
        "show_automatic_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить автоматический вывод установленных приложений"])}
      },
      "label": {
        "amount_range_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма для автоматической отправки/формирования судебного приказа в суд"])},
        "auto_rosreestr_discharge_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое обновление выписки"])},
        "court_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подача в суд"])},
        "court_threshold_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- По сумме задолженности"])},
        "debt_notification_priority_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приоритет уведомлений пользователя"])},
        "debt_notification_time_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время рассылки"])},
        "fees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подача в суд"])},
        "notify_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка госпошлины"])},
        "registry_formation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формирование реестра\nдля оплаты госпошлины"])},
        "rosreestr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ выписки из Росреестра"])},
        "week_days_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будние дни"])},
        "weekend_days_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выходные и праздничные дни"])}
      },
      "priority": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "messengers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мессенджеры"])},
        "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
        "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голос"])}
      },
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
      "send_type": {
        "send_to_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка на почту и в суд"])},
        "send_to_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка на почту"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
    },
    "common": {
      "addStandardizedDebtorAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить кадастровый номер"])},
      "main": {
        "add_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "field": {
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
          "create_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление номера телефона"])},
          "delete_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаления номера телефона"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
          "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
          "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес юридический"])},
          "mail_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес почтовый"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
          "phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
          "phone_number": {
            "is_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валидность номера"])},
            "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])}
          },
          "physical_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес фактический"])},
          "social_networks": {
            "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соцсеть"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соцсеть"])},
            "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на профиль"])}
          },
          "update_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление номера телефона"])},
          "vk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вконтакте"])}
        },
        "remove_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
        "social_network_copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать профиль"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])}
      },
      "organization_details": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "column": {
          "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генеральный директор"])},
          "inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
          "kpp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КПП"])},
          "name_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное наименование организации"])},
          "ogrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОГРН"])},
          "ogrn_data_reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата регистрации"])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты организации"])}
      },
      "owners": {
        "cadastral": {
          "action": {
            "collapseAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть все"])},
            "expandAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раскрыть все"])},
            "selectDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать по умолчанию"])}
          },
          "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Площадь"])},
          "cad_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кадастровая стоимость "])},
          "land_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид"])},
          "object_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По данному адресу не найден кадастровый номер."])},
          "titleFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По данному адресу не найден кадастровый номер.\n Выберите подходящий кадастровый номер из списка"])},
          "update_default_cadnum_toast": {
            "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка обновления данных"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление данных"])},
            "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кадастровый номер успешно обновлен"])}
          }
        },
        "characteristic": {
          "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Площадь"])},
          "cadastral_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кадастровый номер"])},
          "last_request_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последнего запроса"])},
          "request_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата запроса"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сведения о характеристиках объекта недвижимости"])}
        },
        "column": {
          "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения"])},
          "birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место рождения"])},
          "date_of_passport_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата выдачи"])},
          "fraction_in_ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доля в праве"])},
          "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генеральный директор"])},
          "inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
          "kpp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КПП"])},
          "name_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное наименование организации"])},
          "num_of_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия и № паспорта"])},
          "ogrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОГРН"])},
          "ogrn_data_reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата регистрации"])},
          "owner_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственник"])},
          "ownership_registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рег-ции права"])},
          "ownership_registration_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ рег-ции права"])},
          "passport_issued_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кем выдан"])},
          "registered_ownership_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид зарегистрированного права"])}
        },
        "movement": {
          "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период владения"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сведения о переходе прав объекта недвижимости"])},
          "unknown_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственник неизвестен"])}
        },
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственники"])}
      },
      "person_details": {
        "column": {
          "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения"])},
          "birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место рождения"])},
          "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Граж-во"])},
          "date_of_passport_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата выдачи"])},
          "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
          "inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
          "num_of_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия и № паспорта"])},
          "passport_issued_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кем выдан паспорт"])},
          "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
          "registration_address_st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место жительства / Регистрации"])},
          "registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рег-ции"])}
        },
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные о владельце"])}
      },
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая информация"])},
      "tenants": {
        "actions": {
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
          "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "removeConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите удаление жильца"])}
        },
        "column": {
          "birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения"])},
          "birth_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место рождения"])},
          "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Граж-во"])},
          "code_division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код подразделения"])},
          "create_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление жильца"])},
          "date_of_passport_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата выдачи"])},
          "delete_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление жильца"])},
          "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
          "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пол"])},
          "inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
          "num_of_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия и № паспорта"])},
          "passport_is_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паспорт не подтверждён"])},
          "passport_is_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паспорт подтверждён"])},
          "passport_issued_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кем выдан паспорт"])},
          "place_of_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место работы"])},
          "phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
          "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должность"])},
          "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
          "registration_address_st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место жительства / Регистрации"])},
          "registration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рег-ции"])},
          "relationships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Род. отношения"])},
          "snils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СНИЛС"])},
          "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источники данных"])},
          "update_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление жильца"])},
          "where_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куда выбыл"])},
          "deregistration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата снятия с рег. учета"])}
        },
        "enrichment": {
          "source": {
            "efrsb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник: ЕФРСБ"])},
            "fns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник: ФНС"])}
          }
        },
        "gender": {
          "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ж"])},
          "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["М"])}
        },
        "inn": {
          "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН на проверке"])},
          "disqualified": {
            "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c "])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН дисквалифицирован"])},
            "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до "])}
          },
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН не подтверждён"])},
          "invalid_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН недействителен с"])},
          "ip_reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИП зарегистрировано"])},
          "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН не найден"])},
          "not_searched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН не запрашивался"])},
          "org_positions": {
            "founder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учредитель"])},
            "general_director": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генеральный директор"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должность"])}
          },
          "self_employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самозанятый"])},
          "source": {
            "fns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник: ФНС"])},
            "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник: Файловый реестр"])}
          },
          "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН подтверждён"])}
        },
        "passport": {
          "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паспорт на проверке"])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паспорт не подтверждён"])},
          "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паспорт подтверждён"])}
        },
        "registration": {
          "permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Постоянная"])},
          "temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временная"])}
        },
        "snils": {
          "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СНИЛС на проверке"])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СНИЛС не подтверждён"])},
          "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СНИЛС подтверждён"])}
        },
        "sources": {
          "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биллинг"])},
          "egrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка из ЕГРН"])},
          "enrichment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обогащение"])},
          "house_register_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка и домовой книги"])}
        },
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должники"])},
        "tabExecutive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные должника"])}
      },
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить данные"])},
      "update_data_toast": {
        "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка обновления данных"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск данных"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно обновлены"])}
      },
      "vehicle": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "column": {
          "ctc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СТС"])},
          "grz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ГРЗ"])},
          "model_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модель"])},
          "ptc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПТС"])},
          "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион учета"])},
          "tc_type_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип кузова"])},
          "vin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВИН"])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автотранспортное средство"])}
      }
    },
    "company_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Данные компании ", _interpolate(_named("name"))])},
    "courts": {
      "actions": {
        "requisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты участка суда"])},
        "findCourtCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск дела по ФИО"])}
      },
      "column": {
        "case_consideration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рассмотрения"])},
        "writ_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер исполнительного листа"])},
        "case_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор дела"])},
        "case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ дела"])},
        "category_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория дела"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий отказа"])},
        "complaint_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип запроса"])},
        "debt_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период задолженности"])},
        "document_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ документа"])},
        "effective_decision_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата вступления решения в силу"])},
        "filing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата подачи"])},
        "full_names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО должника"])},
        "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "judge_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО судьи"])},
        "log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Движение дела в суде"])},
        "payment_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "status_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История статусов"])},
        "sittings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История заседаний"])}
      },
      "complaint_types": {
        "bank_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос в банк о взыскании и аресте счета"])},
        "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исковое заявление"])},
        "compaint_statetax_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление о выдаче справки на возврат госпошлины (ЗВСП возвращено)"])},
        "complaint_art_331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частная жалоба (ст. 331 ГПК РФ)"])},
        "complaint_art_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление об ускорении рассмотрения дела (ст. 6.1 ГПК РФ)"])},
        "complaint_art_93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление о зачете государственной пошлины (93 ГПК РФ и ст. 333.40 НК РФ)"])},
        "complaint_self_submission_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалоба по возвращенному определению на несоблюдение требований суда на самостоятельное направление ходатайств согласно закону"])},
        "court_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление о вынесении судебного приказа"])},
        "fns_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос в ФНС о счетах"])},
        "fssp_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление в ФСПП"])},
        "petition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ходатайство (заявление)"])},
        "petition_bailiff_court_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ходатайство о направлении судебного приказа на исполнение в службу судебных приставов в электронном виде"])},
        "petition_return_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ходатайство о возвращении заявления о выдаче судебного приказа"])},
        "petition_without_case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление о выдаче справки на возврат госпошлины (ЗВСП не было подано)"])}
      },
      "magistrate": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участок мирового судьи"])}
      },
      "regional": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участок районного суда"])}
      },
      "requisites": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИК"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
        "inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
        "kbk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КБК"])},
        "kpp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КПП"])},
        "magistrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО судьи"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "oktmo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОКТМО"])},
        "payment_recipient_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет получателя платежа"])},
        "payment_recipient_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк получателя платежа"])},
        "payment_recipient_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель платежа"])},
        "phone_number_assistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощник судьи, телефон"])},
        "phone_number_head_of_dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальник управления, телефон"])},
        "phone_number_secretary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретарь судьи, телефон"])}
      },
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справочник суда и судебных дел"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карточка судебных дел"])}
    },
    "documents": {
      "act": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акт"])}
      },
      "actions": {
        "housebook": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить документы"])}
        },
        "judgments": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])}
        },
        "listen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голосовое уведомление"])},
        "my_documents": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить документы"])}
        },
        "protocol_ooc": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить документы"])}
        },
        "pochta": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трек-номер"])}
        },
        "preloaded_charges": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить свод начислений"])}
        },
        "preloaded_penny": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить расчет пени"])}
        },
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить этот документ?"])}
      },
      "agreements": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор"])}
      },
      "banks": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банки"])}
      },
      "claims": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Претензии"])}
      },
      "column": {
        "agreement_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата договора"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма задолженности"])},
        "bailiff_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты пристава"])},
        "bailiff_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО судебного пристава"])},
        "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название банка"])},
        "case_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор дела"])},
        "case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ дела"])},
        "considerated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рассмотрения"])},
        "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата формирования"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата составления"])},
        "debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность"])},
        "debt_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период задолженности"])},
        "debtor_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
        "decision_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата Вынесения"])},
        "document_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма задолженности"])},
        "document_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата оплаты"])},
        "document_formation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата формирования документа"])},
        "document_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер оплаты"])},
        "document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длительность"])},
        "duty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошлина"])},
        "effected_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата вступления в силу"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания ИП"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошлина"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ"])},
        "file_f103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ Ф103"])},
        "fraction_in_ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доля в праве"])},
        "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ заказа выписки"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "initiation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата возбуждения ИП"])},
        "judge_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО судьи"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя оператора"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
        "order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ заявления"])},
        "payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переданные данные"])},
        "payload__address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "payload__half_the_whole_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Половина от общей задолженности"])},
        "payload__penalties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пени"])},
        "payload__sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма задолженности"])},
        "payload__the_whole_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая задолженность"])},
        "penalties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пени"])},
        "penalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пени"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
        "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место"])},
        "pochta_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип отправления"])},
        "received_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата получения документа"])},
        "request_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата запроса"])},
        "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ запроса"])},
        "request_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование запроса"])},
        "request_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип запроса"])},
        "response_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата ответа"])},
        "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено"])},
        "service_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость"])},
        "stage_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этап отправки"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "status_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус изменён в"])},
        "status_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "status_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История статусов"])},
        "status_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "status_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "status_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История статусов"])},
        "tax_inspector_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО инспектора"])},
        "tenant_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО жильца"])},
        "owner_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО собственника"])},
        "termination_ground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основания прекращения"])},
        "termination_ground_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание основания прекращения ИП"])},
        "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая задолженность"])},
        "valid_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
        "valid_until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вес"])}
      },
      "common": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])}
      },
      "egrn_data": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка из ЕГРН"])}
      },
      "egrn_rights": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка ЕГРН\nо переходе прав"])}
      },
      "executions": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнительный лист"])}
      },
      "extra_agreements_column": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма доп. соглашения"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата доп. соглашения"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ доп. соглашения"])},
        "valid_until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия доп. соглашения"])}
      },
      "fee": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПП об\nоплате госпошлины"])}
      },
      "fns": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФНС"])}
      },
      "forms": {
        "paymentDocument": {
          "fields": {
            "document_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма документа"])},
            "document_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата документа"])},
            "document_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер документа"])},
            "estate_object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор собственника"])},
            "debtor_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор жильца"])},
            "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ"])},
            "oktmo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОКТМО"])},
            "recipient_inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН получателя"])},
            "recipient_kpp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КПП получателя"])}
          },
          "toasts": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
            "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно"])}
          }
        }
      },
      "fssp": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФССП"])}
      },
      "guarantee_letter": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гарантийное письмо"])}
      },
      "housebook": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы\n жилищного учета"])}
      },
      "judgments": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебное решение"])}
      },
      "my_documents": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои документы"])}
      },
      "protocol_ooc": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление МКД"])}
      },
      "photos": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотографии нарушений"])}
      },
      "pochta": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трек-номер"])}
      },
      "preloaded_charges": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свод начислений"])}
      },
      "preloaded_penny": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет пени"])}
      },
      "sms": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
      },
      "invoice": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cчет-фактура"])}
      },
      "settlement_statements": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчетная ведомость"])}
      },
      "technical_passport": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Технический паспорт"])}
      },
      "inspection_report": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акт осмотра"])}
      },
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документооборот"])},
      "toasts": {
        "addStandardizedDebtorAddress": {
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка добавления кадастрового номера"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])}
          },
          "no-address": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно добавить кадастровый номер: отсутствует адрес должника"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])}
          },
          "success": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кадастровый номер успешно обновлен"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кадастровый номер обновлен"])}
          }
        }
      },
      "voice": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голосовое оповещение"])}
      }
    },
    "executions": {
      "actions": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить ИП"])},
        "bank_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковские счета"])},
        "fnsRequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты ФНС по адресу"])},
        "fsspRequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты участка ФССП"])},
        "treasuryRequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты казначейства"])}
      },
      "add": {
        "case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер судебного решения"])},
        "court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование судебного участка вынесшего судебное решение"])},
        "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер ИП"])}
      },
      "column": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "bailiff_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО пристава"])},
        "bailiff_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тел. номер пристава"])},
        "case_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата судебного дела"])},
        "case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ судебного дела"])},
        "court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебный участок"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата прекращения ИП"])},
        "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "magistrate_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО судьи"])},
        "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ ИП"])},
        "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата возбуждения ИП"])},
        "status_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "termination_ground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основания прекращения ИП"])},
        "termination_ground_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание основания прекращения ИП"])}
      },
      "debtNatures": {
        "communal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коммунальные услуги"])},
        "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредиты"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Штрафы и пр."])},
        "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налоги"])}
      },
      "errors": {
        "no_birth_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимо указать дату рождения (вкладка жильцы)"])}
      },
      "filters": {
        "debt_nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник"])}
      },
      "fns": {
        "column": {
          "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы"])},
          "ifns_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ ИФНС"])},
          "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
          "request_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обращения"])},
          "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ Запроса"])},
          "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ обращения"])},
          "status_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
        }
      },
      "treasuryRequisites": {
        "treasury_department_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование отдела казначейства"])},
        "treasury_department_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес отдела казначейства"])}
      },
      "bankRequisites": {
        "credit_istitution_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование банка"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес банка"])},
        "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ счёта"])},
        "account_opening_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата открытия счета"])},
        "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид счета"])},
        "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИК"])},
        "inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])}
      },
      "fnsRequisites": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
        "bik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИК"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код ИФНС"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация"])},
        "inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
        "kpp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КПП"])},
        "ks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["к/с"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "payee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель платежа"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
        "reg_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрирующий орган"])},
        "rs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["р/с"])}
      },
      "fssp": {
        "column": {
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип запроса"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма задолженности на сайте ФССП"])},
          "executive_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма задолженности актуальная"])},
          "bailiff_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО пристава"])},
          "bailiff_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тел. номер пристава"])},
          "case_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата судебного дела"])},
          "case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ судебного дела"])},
          "court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебный участок"])},
          "debt_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период задолженности"])},
          "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания / прекращения ИП"])},
          "execution_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип задолженности"])},
          "fssp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФССП"])},
          "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
          "ls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер договора"])},
          "magistrate_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО судьи"])},
          "order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ заявления"])},
          "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ ИП"])},
          "send_to_fssp_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата отправки в ФССП"])},
          "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата возбуждения ИП"])},
          "status_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
          "termination_ground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основания прекращения ИП"])},
          "termination_ground_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание основания прекращения ИП"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер ИЛ"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата ИЛ"])}
        }
      },
      "fsspRequisites": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])}
      },
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справочник ИП"])},
      "tabs": {
        "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковские счета"])},
        "fns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФНС"])},
        "fssp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФССП"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карточка истории ИП в ФССП"])}
    },
    "finance": {
      "accruals": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начислено"])}
      },
      "column": {
        "accruals": {
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])}
        },
        "debts": {
          "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец просрочки"])},
          "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало просрочки"])},
          "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность"])}
        },
        "module_type_1": {
          "accruals": {
            "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])}
          },
          "debts": {
            "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец просрочки"])},
            "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало просрочки"])},
            "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность"])}
          },
          "fee": {
            "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
            "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошлина"])}
          },
          "paid-ups": {
            "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц оплаты"])},
            "for_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц оплаты"])},
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата"])},
            "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата за период"])}
          },
          "penalties": {
            "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало просрочки"])},
            "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пеня"])}
          },
          "recalculations": {
            "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц оплаты"])},
            "for_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц оплаты"])},
            "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата за период"])}
          }
        },
        "module_type_2": {
          "accruals": {
            "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма поездки"])},
            "operator_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор"])},
            "payment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип оплаты"])},
            "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПВП/Полоса"])},
            "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
            "transaction_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время поездки"])}
          },
          "debts": {
            "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец просрочки"])},
            "operator_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор"])},
            "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПВП/Полоса"])},
            "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало просрочки"])},
            "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность"])}
          },
          "paid-ups": {
            "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма поездки"])},
            "operator_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор"])},
            "payment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип оплаты"])},
            "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПВП/Полоса"])},
            "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
            "transaction_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время поездки"])}
          },
          "penalties": {
            "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало просрочки"])},
            "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пеня"])}
          }
        },
        "paid-ups": {
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц оплаты"])}
        },
        "penalties": {
          "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало просрочки"])},
          "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пеня"])}
        },
        "vehicle-accruals": {
          "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма поездки"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время поездки"])},
          "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПВП/Полоса"])},
          "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])}
        }
      },
      "debts": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность"])}
      },
      "fee": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошлина"])}
      },
      "incomeSource": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник дохода"])}
      },
      "paid-ups": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])}
      },
      "penalties": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пеня"])}
      },
      "recalculations": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перерасчет"])}
      },
      "summaries": {
        "accruals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начислено"])},
        "debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошлина"])},
        "paid-ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])},
        "penalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пеня"])},
        "totalDebts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая задолженность"])},
        "actualDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж. актуальная"])}
      },
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансовые данные"])},
      "totalDebts": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая задолженность"])}
      },
      "vehicle-accruals": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начислено"])}
      }
    },
    "history": {
      "documents_values": {
        "add_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление"])},
        "delete_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление"])},
        "update_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление"])}
      },
      "menu": {
        "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы"])},
        "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансовые данные"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая информация"])}
      },
      "modules": {
        "general_main": {
          
        },
        "paid": {
          "add_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление платежа"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц оплаты"])},
          "delete_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление платежа"])},
          "for_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц оплаты"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата"])},
          "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата за период"])}
        }
      },
      "status": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгрузка"])},
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр"])}
      },
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История изменений"])},
      "value_tab": {
        "act": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акт"])},
        "agreements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор"])},
        "banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банки"])},
        "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])},
        "debtor_enrichment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обогащение"])},
        "egrn_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка из ЕГРН"])},
        "egrn_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка ЕГРН о переходе прав"])},
        "executions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнительный лист"])},
        "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПП об оплате госпошлины"])},
        "fns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФНС"])},
        "general_main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
        "guarantee_letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гарантийное письмо"])},
        "housebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы жилищного учета"])},
        "judgments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебное решение"])},
        "my_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои документы"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])},
        "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотографии нарушений"])},
        "pochta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трек-номер"])},
        "preloaded_charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свод начислений"])},
        "preloaded_penny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет пени"])},
        "protocol_ooc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протокол ОСС"])},
        "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
        "tenant_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список жильцов"])},
        "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голосовое оповещение"])}
      }
    },
    "notifications": {
      "actions": {
        "listen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голосовое уведомление"])}
      },
      "column": {
        "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создано"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя оператора"])},
        "payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переданные данные"])},
        "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
        "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено"])},
        "service_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "status_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус изменён в"])},
        "status_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
        "payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата платежа"])},
        "payment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма платежа"])}
      },
      "sms": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
      },
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карточка уведомлений"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карточка уведомлений"])},
      "voice": {
        "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голосовые уведомления"])},
        "tabs": {
          "planned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланированные"])},
          "commited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Совершенные"])}
        }
      }
    },
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Данные должника ", _interpolate(_named("name"))])}
  },
  "debtorIcon": {
    "email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Оповещение по email: ", _interpolate(_named("status"))])},
    "sms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("status"))])},
    "voice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Голосовое оповещение: ", _interpolate(_named("status"))])}
  },
  "debtors": {
    "action": {
      "attachEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначить сотрудника"])},
      "asuUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить данные из АСУ ОДС"])},
      "aisGorodUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить данные из АИС-город"])},
      "borUpdateFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить данные из БОР (пени)"])},
      "borUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить данные из БОР (задолженность)"])},
      "change_status_judicial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести в исполнительное производство"])},
      "change_status_pretrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести в судебное производство"])},
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка досудебного требования"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать номера ЛС"])},
      "copyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать адреса"])},
      "copyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать имена"])},
      "egrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка ЕГРН"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить уведомление на почту"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реестр оплаты пошлины"])},
      "postal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовая опись"])},
      "lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировать отправку уведомлений должникам"])},
      "megapolisUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить данные из Мегаполис ЖКХ"])},
      "pikUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить данные из SQL"])},
      "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Печать и формирование документов"])},
      "recognition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распознать загруженные документы"])},
      "setOfCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свод начислений по ЛС"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки автоматизации"])},
      "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка SMS"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить статус выбранных должников"])},
      "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблокировать отправку уведомлений должникам"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск данных по должнику"])},
      "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голосовое уведомление"])},
      "voice_call_queue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление очередями обзвона"])}
    },
    "actions": {
      "aisGorodUpdate": {
        "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить данные из АИС-город"])}
      }
    },
    "column": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "attached_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответственный"])},
      "bailiff_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО пристава"])},
      "case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ дела"])},
      "company_module_type_1": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "attached_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответственный"])},
        "bailiff_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО пристава"])},
        "case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ дела"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания ИП"])},
        "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО / Орг"])},
        "okrug_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
        "personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЛС / Договор"])},
        "phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
        "filing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата подачи в суд"])},
        "register_compilation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата составления реестра"])},
        "last_sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновления фин. данных"])},
        "court_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты участка суда"])},
        "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ ИП"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор"])},
        "status_updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновл. статуса"])},
        "total_accrual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начисл."])},
        "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность по номиналу ИД"])},
        "total_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж."])},
        "total_debt_entrepreneur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж. по ИП"])},
        "total_executive_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность предъявленная к исполнению"])},
        "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошлина"])},
        "total_paid_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])},
        "total_penalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пени"])},
        "total_total_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ. задолж."])},
        "total_actual_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж. актуальная"])},
        "last_active_court_case_history_sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последней синхронизации активной истории судебных дел"])},
        "last_archive_court_case_history_sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последней синхронизации архивной истории судебных дел"])}
      },
      "company_module_type_2": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "attached_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответственный"])},
        "bailiff_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО пристава"])},
        "case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ дела"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания ИП"])},
        "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО / Орг"])},
        "okrug_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
        "personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ ГРЗ"])},
        "phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
        "receipt_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата подачи в суд"])},
        "register_compilation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата составления реестра"])},
        "last_sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновления фин. данных"])},
        "filing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата подачи в суд"])},
        "court_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты участка суда"])},
        "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ ИП"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор"])},
        "status_updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновл. статуса"])},
        "total_accrual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начисл."])},
        "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность по номиналу ИД"])},
        "total_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж."])},
        "total_debt_entrepreneur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж. по ИП"])},
        "total_executive_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность предъявленная к исполнению"])},
        "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошлина"])},
        "total_paid_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])},
        "total_penalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пени"])},
        "total_total_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ. задолж."])},
        "total_actual_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж. актуальная"])},
        "last_active_court_case_history_sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последней синхронизации активной истории судебных дел"])},
        "last_archive_court_case_history_sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последней синхронизации архивной истории судебных дел"])}
      },
      "company_module_type_4": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "attached_employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответственный"])},
        "bailiff_full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО пристава"])},
        "case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ дела"])},
        "court_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование суда"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания ИП"])},
        "filing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата подачи в суд"])},
        "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО / Орг"])},
        "okrug_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
        "personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЛС / Договор"])},
        "phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
        "receipt_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата подачи в суд"])},
        "register_compilation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата составления реестра"])},
        "last_sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновления фин. данных"])},
        "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ ИП"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор"])},
        "status_updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновл. статуса"])},
        "total_accrual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начисл."])},
        "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность по номиналу ИД"])},
        "total_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж."])},
        "total_debt_entrepreneur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж. по ИП"])},
        "total_executive_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность предъявленная к исполнению"])},
        "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошлина"])},
        "total_paid_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])},
        "total_penalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пени"])},
        "total_total_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ. задолж."])},
        "total_actual_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж. актуальная"])},
        "last_active_court_case_history_sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последней синхронизации активной истории судебных дел"])},
        "last_archive_court_case_history_sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последней синхронизации архивной истории судебных дел"])}
      },
      "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания ИП"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
      "okrug_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
      "personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЛС / Договор"])},
      "phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
      "receipt_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата подачи в суд"])},
      "register_compilation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата составления реестра"])},
      "last_sync_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновления фин. данных"])},
      "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ ИП"])},
      "status_updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновления статуса"])},
      "total_accrual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начисл."])},
      "total_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж."])},
      "total_debt_entrepreneur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолженность по ИП"])},
      "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошлина"])},
      "total_paid_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено"])},
      "total_penalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пени"])},
      "total_total_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общ. задолж."])},
      "total_actual_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задолж. актуальная"])}
    },
    "executive": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнительное\n производство"])}
    },
    "filterGroupLabels": {
      "court_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебные данные"])},
      "debtor_debt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма долга"])},
      "debtor_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус должника"])},
      "extra_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доп. параметры"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные должника"])}
    },
    "filters": {
      "accruals_from;accruals_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период образования задолженности"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "bailiff_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По участкам ФССП"])},
      "birth_date": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль жильца: дата рождения"])}
      },
      "birth_place": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль жильца: место рождения"])}
      },
      "case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ дела"])},
      "cohabitees": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Совместная собственность"])}
      },
      "track_number": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы: трек-номер"])}
      },
      "court_type_latest": {
        "arbitr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитражный"])},
        "mirsud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мировой"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подано в суд"])},
        "rajsud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Районный"])}
      },
      "debtor_debt_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма долга до"])},
      "debtor_debt_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма долга от"])},
      "doc_court_decision": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы: судебное решение"])}
      },
      "invoice": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы: счет-фактура"])}
      },
      "doc_debtor": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы: мои документы"])}
      },
      "doc_egrn": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы: выписка из ЕГРН"])}
      },
      "doc_egrn_rights": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы: выписка из ЕГРН о переходе прав"])}
      },
      "doc_house_register": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы: домовая книга"])}
      },
      "doc_payment": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присутствует"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствует"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы: Госпошлина по дольщикам"])}
      },
      "doc_protocol_ooc": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы: протокол ОСС"])}
      },
      "egrn_status": {
        "!statement_ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не заказана"])},
        "!statement_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствует"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус выписки ЕГРН"])},
        "statement_ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказана"])},
        "statement_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присутствует"])}
      },
      "employee_attached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответственный сотрудник"])},
      "register_compilation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата составления реестра"])},
      "estate_owner_known": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Известен"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестен"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственник"])}
      },
      "fee_status": {
        "!fees_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не оплачена"])},
        "fees_await_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидает оплату"])},
        "fees_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачена"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус оплаты пошлины"])}
      },
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО / Организация"])},
      "has_bailiff": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подсудности по - ФССП"])}
      },
      "has_cad_num": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кадастровый номер"])}
      },
      "has_documents": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы"])}
      },
      "has_enrichment": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должник обогащен"])}
      },
      "has_ifns": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФНС"])}
      },
      "has_judicial_by_magistrate": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подсудность по мировым судьям"])}
      },
      "has_judicial_by_regional": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подсудность по районным судьям"])}
      },
      "has_phone_number": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])}
      },
      "has_registration_address": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес регистрации"])}
      },
      "has_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS-уведомление"])},
      "has_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Голосовое уведомление"])},
      "has_writ_of_execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наличие ИП"])},
      "image": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотофиксация"])}
      },
      "inn": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль жильца: ИНН"])}
      },
      "is_archived": {
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивные должники"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать"])}
      },
      "is_archived_manual": {
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивные должники (ручной)"])}
      },
      "is_bankrupt": {
        "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкрот"])},
        "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непроверенный"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])}
      },
      "is_organization": {
        "!is_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Физическое лицо"])},
        "is_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическое лицо"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип должника"])}
      },
      "is_verified_cad_num": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кадастровый номер подтвержден"])}
      },
      "is_verified_result": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес подтвержден"])}
      },
      "has_last_active_court_case_history_sync_date": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последней синхронизации активной истории судебных дел"])}
      },
      "has_last_archive_court_case_history_sync_date": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последней синхронизации архивной истории судебных дел"])}
      },
      "magistrate_court_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор мирового судьи"])},
      "num_of_passport": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль жильца: номер паспорта"])}
      },
      "owner_has_enrichment": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственник обогащен"])}
      },
      "owners": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственники"])}
      },
      "personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЛС / Договор"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
      "regional_court_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор районного суда"])},
      "save_filters_as_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запомнить текущий фильтр"])},
      "search_name_deep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкл. Жильцов"])},
      "serial_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ ИП"])},
      "shareholders": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дольщики"])}
      },
      "snils_exists": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СНИЛС"])}
      },
      "status_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "status_updated_at_before;status_updated_at_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период смены статуса"])},
      "unsend_into_billing": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не отправлен"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлен"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус отправки в биллинг"])}
      },
      "vehicles_birth_date": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения"])}
      },
      "vehicles_birth_place": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место рождения"])}
      },
      "vehicles_inn": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инн"])}
      },
      "vehicles_num_of_passport": {
        "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
        "hasNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер паспорта"])}
      }
    },
    "judicial": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебное\n производство"])}
    },
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников"])},
    "pretrial": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Досудебное\n производство"])}
    }
  },
  "debtorsActions": {
    "wholeSelectedNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данное действие доступно только для конкретно выбранных должников"])}
  },
  "debtorStatus": {
    "addSubEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить подстатус"])},
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для смены статуса"])},
    "selectEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите статус"])},
    "subEntryComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
    "subEntryValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подстатус"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус работы с должником"])}
  },
  "dialogs": {
    "voice_call_queue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление очередями обзвона"])},
      "column": {
        "is_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид формирования листа обзвона"])},
        "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланировано номеров"])},
        "called": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзвонили номеров"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата формирования листа обзвона"])}
      },
      "is_auto": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ручной"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматический"])},
        
      ]
    },
    "debtorPochta": {
      "actions": {
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
      },
      "fields": {
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл"])},
        "file_f103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл Ф103"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трек-номер"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вес (гр.)"])}
      },
      "title": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление трек-номера"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование трек-номера"])}
      },
      "toasts": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка добавления трек-номера"])},
        "success": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трек-номер добавлен"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трек-номер обновлен"])}
        }
      }
    }
  },
  "dict": {
    "companyModuleType": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЖКХ"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автотранспорт"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оказание услуг"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банки, МФО"])}
    },
    "companyPeniCalculationTypes": {
      "155": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["155 ЖК РФ (ч.14)"])},
      "155_14_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["155 ЖК РФ (ч.14.1)"])},
      "395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["395 ГК РФ"])},
      "395_pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["395 ГК РФ (фиксированный процент договорной неустойки)"])}
    },
    "companyTaxationSystems": {
      "osn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая система налогообложения"])},
      "usn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упрощённая система налогообложения"])}
    },
    "baseTypeOptions": {
      "Смешанный договор": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смешанный договор"])},
      "Трудовой договор": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трудовой договор"])},
      "Договор ренты": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор ренты"])},
      "Договор страхования": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор страхования"])},
      "Налоги, сборы и иные взыскания": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налоги, сборы и иные взыскания"])},
      "Акт государственного органа": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акт государственного органа"])},
      "Договор энергоснабжения": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор энергоснабжения"])},
      "Договор лизинга": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор лизинга"])},
      "Внешнеторговый договор": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешнеторговый договор"])},
      "Договор оказания услуг": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор оказания услуг"])},
      "Договор дарения": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор дарения"])},
      "Договор хранения": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор хранения"])},
      "Договор аренды": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор аренды"])},
      "Договор перевозки": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор перевозки"])},
      "Договор авторского заказа": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор авторского заказа"])},
      "Лицензионный договор": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензионный договор"])},
      "Договор об отчуждении исключительного права": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор об отчуждении исключительного права"])},
      "Кредитный договор": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитный договор"])},
      "Договор поставки": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор поставки"])},
      "Иной договор": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иной договор"])},
      "Договор купли-продажи": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор купли-продажи"])},
      "Договор подряда": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор подряда"])}
    },
    "employeeEventNotifications": {
      "debtor_court_decision_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчет по загрузке судебных решений"])},
      "debtor_data_registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реестр оплаты пошлины"])},
      "debtor_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сформированные документы"])},
      "debtor_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчёты по должникам"])}
    },
    "employeeRoles": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Админ"])},
      "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сотрудник"])},
      "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гость"])},
      "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджер"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец"])}
    },
    "penaltyKeyRates": {
      "court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На день подачи в суд (сегодня)"])},
      "k_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По периодам действия ставки рефинансирования"])},
      "p_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На день частичной оплаты"])},
      "p_pen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По фиксированному проценту договорной неустойки"])}
    }
  },
  "documentField": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])}
  },
  "dutyForm": {
    "dialog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с документами"])}
    },
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для формирования реестра для оплаты пошлины"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр и печать"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с печатной формой"])},
    "toast": {
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка формирования документов"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формирование бланка пошлины..."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ готов!"])}
    }
  },
  "postal": {
    "dialog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сформировать почтовую опись"])}
    },
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для формирования почтовой описи"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр и печать"])},
    "toast": {
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка формирования почтовой описи"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формирование почтовой описи..."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ готов!"])}
    }
  },
  "egrn": {
    "attention": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы не гарантируем корректную работа сайта Росреестра. Время\n      получения выписки по регламенту Росреестра занимает до 5 дней."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Важно!"])}
    },
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обращаем Ваше внимание, что произойдет списание с Вашего лицевого счета суммы за услугу по получению выписки ЕГРН с официального сайта Росреестра, согласно тарифному плану."])},
    "field": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка из ЕГРН"])},
      "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка из ЕГРН о переходе прав"])}
    },
    "force": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перезаказать"])},
    "ignore_norights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказать без учета наличия прав"])},
    "ignore_norights_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В выписках из ЕГРН могут отсутствовать данные о владельцах"])},
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для формирования выписки из ЕГРН"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить запрос"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получение выписок из ЕГРН"])},
    "toast": {
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка формирования запроса выписки"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ выписки из ЕГРН"])},
      "progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("current")), " из ", _interpolate(_named("max"))])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос на выписку из ЕГРН успешно сформирован"])}
    }
  },
  "employee": {
    "add": {
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
      },
      "employee_role": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите роль сотрудника"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите роль сотрудника"])}
      },
      "event_notifications": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления пользователя"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите уведомления"])}
      },
      "first_name": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])}
      },
      "last_name": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])}
      },
      "linked_companies": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставить доступ следующим компаниям"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставить доступ следующим компаниям"])}
      },
      "middle_name": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчество"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчество"])}
      },
      "modules": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел доступа"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип производства"])}
      },
      "permissions": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите доступы"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите доступы"])}
      },
      "position": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должность"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должность"])}
      },
      "resend_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выслать пароль повторно"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить сотрудника"])},
      "user_phone": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моб. телефон"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моб. телефон"])}
      }
    }
  },
  "enrichment": {
    "update_encr_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
    "update_encr_fio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО и дата рождения"])},
    "update_encr_inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
    "update_encr_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паспорт"])},
    "update_encr_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "update_encr_snils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СНИЛС"])},
    "update_inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН/проверка действительности паспорта"])},
    "update_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные по ИНН / ОГРН"])},
    "update_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ГРЗ"])}
  },
  "eulaDialog": {
    "actions": {
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять"])},
      "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензионный договор - оферта"])},
    "toasts": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка загрузки оферты"])}
    }
  },
  "exchange": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания"])},
    "dataType": {
      "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор"])},
      "egrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЕГРН"])},
      "executive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнительное производство"])},
      "housebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы жилищного учета"])},
      "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкция"])},
      "judgment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебный приказ"])},
      "judicial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебное производство"])},
      "oosProtocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протокол ОСС"])},
      "paymentOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платёжное поручение"])},
      "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фото нарушений"])},
      "pretrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Досудебное производство"])},
      "pochta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трек-номер"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет-фактура"])}
    },
    "dataTypeDescription": {
      "housebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распознание данных «Документы жилищного учета» \\ «Выписки из паспортного стола» и автоматизированная загрузка данных и файла в карточку должника с помощью нейронной сети. Загружать файлы необходимо одна выписка из домовой книги – один файл. Количество одновременно загружаемых файлов неограниченно."])},
      "judgment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распознание данных вынесенного «Судебного приказа» и автоматизированная загрузка данных и файла в карточку должника с помощью нейронной сети. Загружать файлы необходимо один судебный приказ– один файл. Количество одновременно загружаемых файлов неограниченно."])},
      "oosProtocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распознание данных «Протокол ОСС» \" и автоматизированная загрузка данных и файла в карточку должника с помощью нейронной сети. Загружать файлы необходимо один протокол ОСС – один файл. Количество одновременно загружаемых файлов неограниченно."])},
      "paymentOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распознание данных «Платежного поручения» по оплате пошлины и автоматизированная загрузка данных и файла в карточку должника с помощью нейронной сети. Загружать файлы необходимо одно платежное поручение – один файл. Количество одновременно загружаемых файлов неограниченно."])}
    },
    "export": {
      "actions": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгрузить"])},
        "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланировать"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])}
      },
      "dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчет готов"])}
      },
      "form": {
        "court_tracker": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по дате подачи"])}
        },
        "date": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по периоду долга"])}
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по периоду email-уведомления"])}
        },
        "enrichment": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по дате обогащения данных"])}
        },
        "groupBy": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по действию"])},
          "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Периоду долга"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разбить по"])},
          "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дате проведенной работы"])}
        },
        "hasCourtCaseId": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор судебного дела"])},
          "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не учитывать"])},
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])}
        },
        "hasDoc": {
          "doc_egrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка ЕГРН"])},
          "doc_egrn_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выписка ЕГРН о переходе прав"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные ЕГРН"])},
          "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не учитывать"])}
        },
        "hasEnrichment": {
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должник обогащен"])},
          "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не учитывать"])},
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестно"])}
        },
        "hasOptionsDefault": {
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
          "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не учитывать"])},
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])}
        },
        "hasTenantAnd": {
          "cohabitees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Совместная собственность"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по собственнику"])},
          "shareholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дольщики"])},
          "singleholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Один собственник"])}
        },
        "isOrganization": {
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Физическое лицо"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Физ/Юр"])},
          "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не учитывать"])},
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическое лицо"])}
        },
        "sms": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по периоду смс-уведомления"])}
        },
        "status_updated_at": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата смены статуса"])}
        },
        "templateTypeId": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип шаблона"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите шаблон"])}
        },
        "voice": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по периоду голосового-уведомления"])}
        },
        "voice_document_date": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период совершения звонка"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгрузка данных\\\nОтчетность"])},
      "toast": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка выгрузки"])},
        "noDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите хотя бы один из интервалов"])},
        "noGroupBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите вариант разбивки"])},
        "noTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип отчета"])},
        "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогресс"])},
        "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подготовка отчета"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы получены"])}
      }
    },
    "import": {
      "form": {
        "activeTemplatePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите шаблон"])},
        "commonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])},
        "disableCalculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет задолженности"])},
        "dropZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просто перетащите файлы сюда"])},
        "errorHasColumnWithUnsetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип для всех столбцов или обратитесь в тех.поддержку"])},
        "housebook": {
          "noTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите шаблон документа жилищного учета"])},
          "templateSelect": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По шаблону"])},
            "options": {
              "template-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои документы"])},
              "template-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["МФЦ"])}
            }
          }
        },
        "integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция"])},
        "mode": {
          "linear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линейный"])},
          "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Табличный"])}
        },
        "noRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимо выбрать регион"])},
        "oosProtocol": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузку ОСС (общее собрание собственников) необходимо выполнять в формате PDF с указанием адреса дома: Бирюлевская д.1 к.2.pdf"])}
        },
        "period": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить всё"])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить по месяцам"])}
        },
        "productionTypePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип производства"])},
        "side": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачайте на компьютер файл шаблона, сгенерированного программой Excel. Заполните его значениями"])},
          "linearTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линейные шаблоны"])},
          "tableTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Табличные шаблоны"])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])},
        "title": {
          "contract": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " договор"])},
          "egrn": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " ЕГРН"])},
          "executive": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " файл в исполнительное производство"])},
          "housebook": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " документы жилищного учета"])},
          "judgment": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " судебный приказ"])},
          "judicial": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " файл в судебное производство"])},
          "loanAgreement": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " кредитные договоры"])},
          "oosProtocol": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " протокол ОСС"])},
          "paymentOrder": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " платёжное поручение"])},
          "photos": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " фото нарушений"])},
          "pretrial": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " файл в досудебное производство"])},
          "pochta": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " трек-номер"])},
          "invoice": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("exchange.import.form.commonTitle"), " счет-фактуру"])}
        },
        "toast": {
          "submit": {
            "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка загрузки"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с документами"])},
            "messagePhotos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с файлами"])},
            "progresses": {
              "caching": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Кэширование ", _interpolate(_named("current")), " из ", _interpolate(_named("max"))])},
              "checking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обработка ", _interpolate(_named("current")), " из ", _interpolate(_named("max"))])},
              "parsing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Парсинг ", _interpolate(_named("current")), " из ", _interpolate(_named("max"))])},
              "syncing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Синхронизация ", _interpolate(_named("current")), " из ", _interpolate(_named("max"))])},
              "uploading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Загрузка ", _interpolate(_named("current")), " из ", _interpolate(_named("max"))])}
            },
            "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл(ы) успешно загружен(ы)"])}
          }
        },
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить\nфайлы"])}
      },
      "instructions": {
        "file": {
          "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Чтобы выполнить загрузку Вашего файла, напишите нам на почту: ", _interpolate(_named("email")), " или оставьте заявку в чате, и мы сделаем шаблон для Вас и все последующие выгрузки будете осуществлять используя Ваш файл."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственный файл"])}
        },
        "integration": {
          "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Если у Вас в организации используется программа 1С или другой программный комплекс учета начислений, напишите нам на почту: ", _interpolate(_named("email")), " или оставьте заявку в чате и мы сделаем безопасную интеграцию с нашей платформой, что позволит все данные получать в онлайн режиме.\n\n          Для того чтобы выгрузить файлы (исключение п.3) Вам нужно выбрать год и разложить файлы по нужным месяцам, после этого нажать импортировать. Ожидаем..."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция по API"])}
        },
        "template": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для того, чтобы использовать шаблоны, нажмите на нужный формат файла шаблона (csv, xls. xlsx) и скачайте его. Файл шаблона нужно заполнять строго как в примере. Все положения столбцов должны быть как в шаблоне, даже если они не используются.\n\n          В свою очередь в файле идет разбивка по домам, в виде блоков последовательных строк. Начинается блок названием улицы и дома (Ленина ул. д.2) и номеров квартир, далее идет название следующей улицы и следующие номера квартир и так далее..."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкция работы с файлом\n выгрузки должников"])}
      },
      "templates": {
        "toast": {
          "assignError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось выбрать шаблон по умолчанию"])},
          "fetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка загрузки шаблонов"])},
          "unassignError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось убрать шаблон по умолчанию"])}
        },
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый шаблон"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка данных"])}
    },
    "integration": {
      "actions": {
        "close": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
        },
        "delete": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка удаления данных"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные удалены"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить данные"])}
        },
        "save": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения данных"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные сохранены"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все поля"])}
        }
      },
      "ais_gorod": {
        "form": {
          "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["База данных"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "production_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип производства"])},
          "provider_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коды поставщика услуг"])},
          "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расписание"])},
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
          "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальный месяц"])},
          "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
          "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])}
        },
        "messages": {
          "noRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В данной учетной записи отсутствуют прикрепленные компании, попробуйте выйти и зайти заново"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Госуслуги"])}
      },
      "asu": {
        "form": {
          "production_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип производства"])}
        }
      },
      "api": {
        "actions": {
          "generateNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать новый"])}
        },
        "fields": {
          "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ API"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ API"])},
        "toast": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка генерации API-ключа"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый API-ключ успешно сгенерирован"])}
        }
      },
      "autodor": {
        "actions": {
          "close": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
          },
          "delete": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить учетные данные"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка удаления данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старые данные удалены"])}
            }
          },
          "send": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно сохранены"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все поля"])}
            }
          }
        },
        "form": {
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта России"])}
      },
      "billing": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биллинг"])}
      },
      "common": {
        "actions": {
          "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить учетные данные"])},
          "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
        }
      },
      "gosuslugi": {
        "actions": {
          "close": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
          },
          "delete": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти из госуслуг"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка выхода из госуслуг"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход произведен"])}
            }
          },
          "send": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно сохранены"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все поля"])}
            }
          },
          "sendExtraInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])}
        },
        "form": {
          "mos_ru_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль от mos.ru"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "rolename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование организации"])},
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email или номер телефона"])}
        },
        "messages": {
          "noRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В данной учетной записи отсутствуют прикрепленные компании, попробуйте выйти и зайти заново"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Госуслуги"])}
      },
      "imap": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка почты для отправки и получения"])},
        "form": {
          "smtp_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер SMTP"])},
          "smtp_port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порт SMTP"])},
          "smtp_ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP шифрование"])},
          "smtp_ssl_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип шифрования"])},
          "imap_ssl_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип шифрования"])},
          "imap_ssl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMAP шифрование"])},
          "imap_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер IMAP"])},
          "imap_port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порт IMAP"])},
          "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "sender_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя отправителя"])}
        },
        "actions": {
          "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить тестовое сообщение"])}
        }
      },
      "imobis": {
        "actions": {
          "close": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
          },
          "delete": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить учетные данные"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка удаления данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старые данные удалены"])}
            }
          },
          "send": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно сохранены"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все поля"])}
            }
          }
        },
        "form": {
          "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ API"])},
          "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя отправителя"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imobis"])}
      },
      "judgement": {
        "actions": {
          "delete": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить учетные данные"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка удаления данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старые данные удалены"])}
            }
          },
          "send": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно сохранены"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните оба поля"])}
            }
          }
        },
        "imagesForm": {
          
        },
        "settingsForm": {
          "actions": {
            "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
          },
          "fields": {
            "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертификат"])},
            "is_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделать облачное ЭЦП доступным для всех"])},
            "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец"])},
            "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действителен"])},
            "thumbprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отпечаток подписи"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP:port до stunnel"])},
            "use_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать публичный ключ"])}
          },
          "toast": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения настроек"])},
            "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки сохранены"])}
          }
        },
        "sign": {
          "field": {
            
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация криптоотпечатка"])},
          "toast": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения отпечатка"])},
            "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация отпечатка сохранена"])}
          }
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Графические подписи - ЭЦП"])}
      },
      "kvint": {
        "actions": {
          "send": {
            "toast": {
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимо заполнить поле \"ID\""])}
            }
          }
        },
        "form": {
          "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvint"])}
      },
      "megapolis": {
        "form": {
          "production_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип производства"])},
          "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расписание"])},
          "receive_penalties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать пеню"])},
          "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало отчёта (справки) о начислениях и оплате по л/с"])}
        }
      },
      "pik": {
        "form": {
          "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хост"])},
          "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["База данных"])},
          "production_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип производства"])},
          "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расписание"])}
        }
      },
      "notifications": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])}
      },
      "pochta": {
        "actions": {
          "close": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
          },
          "delete": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить учетные данные"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка удаления данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старые данные удалены"])}
            }
          },
          "send": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно сохранены"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все поля"])}
            }
          }
        },
        "form": {
          "is_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделать публичным"])},
          "pochta_ru_use_urrobot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["использовать API ключ \"ЮРРОБОТ\" (необходима доверенность)"])},
          "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен авторизации"])},
          "use_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать публичный ключ"])},
          "user_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен пользователя"])}
        },
        "formGosPochta": {
          "user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID пользователя"])},
          "user_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен пользователя"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта России"])}
      },
      "sendPulse": {
        "actions": {
          "close": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
          },
          "delete": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить учетные данные"])}
          },
          "send": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
            "toast": {
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все поля"])}
            }
          }
        },
        "form": {
          "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id клиента"])},
          "secret_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный ключ"])},
          "sender_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя отправителя"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SendPulse"])}
      },
      "services": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервисы"])}
      },
      "smsc": {
        "actions": {
          "close": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
          },
          "delete": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить учетные данные"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка удаления данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старые данные удалены"])}
            }
          },
          "send": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно сохранены"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все поля"])}
            }
          }
        },
        "form": {
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "sender_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя отправителя"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS Центр"])}
      },
      "smsTraffic": {
        "form": {
          "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
          "sender_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя отправителя"])}
        },
        "actions": {
          "close": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
          },
          "delete": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить учетные данные"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка удаления данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старые данные удалены"])}
            }
          },
          "send": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
            "toast": {
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения данных"])},
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно сохранены"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все поля"])}
            }
          }
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция"])},
      "white_label": {
        "actions": {
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
        },
        "fields": {
          "auth_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фон на странице приветствия (разрешение 368х113)"])},
          "auth_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип на странице приветствия (разрешение 368х113)"])},
          "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домен компании"])},
          "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип (разрешение 196x60)"])},
          "logo_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подпись компании"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смена логотипа"])}
      }
    },
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
    "templates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конструктор реестра загрузки"])}
    }
  },
  "file": {
    "csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV\n файл"])},
    "xls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XLS\n файл"])},
    "xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XLSX\n файл"])}
  },
  "fileDialog": {
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка скопирована"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать ссылку"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть и посмотреть"])}
  },
  "globalLoader": {
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получение данных от сервера"])},
    "express": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание формирования ответа от сервера express"])},
    "html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка html-кода страницы"])},
    "interpolation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод данных"])},
    "loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать\n    на платформу по автоматизации взыскания задолженностей"])},
    "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формирование объектов"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление глобального хранилища"])}
  },
  "header": {
    "stat": {
      "decisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вынесено решение"])},
      "inCourt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подано в суд"])},
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В работе"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый"])},
      "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отозвано"])}
    },
    "user": {
      "adminPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панель администратора"])},
      "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль"])},
      "changeTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение темы"])},
      "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])}
    }
  },
  "listen": {
    "duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " сек."])},
    "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет файла"])}
  },
  "lock": {
    "dialog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировать выбранных должников для отправки уведомлений?"])}
    }
  },
  "moveDebtorsDialog": {
    "confirmLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])},
    "judicial": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести выбранных должников в раздел \"Исполнительное производство\"?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод должников в раздел \"Исполнительное производство\""])}
    },
    "pretrial": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести выбранных должников в раздел \"Судебное производство\"?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод должников в раздел \"Судебное производство\""])}
    },
    "toast": {
      "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Перенесено частично: часть должников ", _interpolate(_named("debtor_ids")), " не может быть перенесена по причине конфликтов"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка переноса"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенос должников"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет перенос..."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должники успешно перенесены"])},
      "totalConflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ни один из должников ", _interpolate(_named("debtor_id")), " не был перенесен по причине конфликтов"])}
    }
  },
  "non_selected_items_dialog": {
    "debtors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников"])}
  },
  "notification": {
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить Досудебное требование?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить Email-уведомление?"])},
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для уведомления"])},
    "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить SMS-уведомление?"])},
    "toast": {
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка отправки уведомлений"])},
      "failure_mono": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ошибка отправки уведомления: ", _interpolate(_named("message"))])},
      "failure_no_operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите данные для СМС уведомлений (нажмите, чтобы перейти)"])},
      "failure_partial_multi": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Доставлено ", _interpolate(_named("delivered")), " из ", _interpolate(_named("max")), ".\nЧасть уведомлений (", _interpolate(_named("errors_count")), ") не были отправлены"])},
      "failure_total_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка. Ни одно из уведомлений не было отправлено"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка уведомления"])},
      "message_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка уведомлений"])},
      "message_voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка голосового уведомления"])},
      "message_voice_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка голосовых уведомлений"])},
      "progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обработано: ", _interpolate(_named("current")), " из ", _interpolate(_named("max"))])},
      "sentProgress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Отправлено: ", _interpolate(_named("current")), " из ", _interpolate(_named("max"))])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление успешно отправлено"])},
      "success_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления успешно отправлены"])}
    },
    "voice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить голосовое уведомление?"])}
  },
  "other": {
    "afterMonthsPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " месяц"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
    "currencyPerItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " руб/шт"])},
    "currencyPerUnit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " руб/", _interpolate(_named("unit"))])},
    "dateFromNow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["с ", _interpolate(_named("from")), " по настоящее время"])},
    "dateFromTo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["с ", _interpolate(_named("from")), " по ", _interpolate(_named("to"))])},
    "daysPeriodPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Раз в ", _interpolate(_named("n")), " дней"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "monthPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Каждый ", _interpolate(_named("n")), " месяц"])},
    "multifilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мультифильтр"])},
    "multifilterReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Н/Д"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
    "perMonthPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " раз в месяц"])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сек."])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите"])},
    "updateAvailableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступна новая версия, необходимо обновиться. Несохраненные данные будут утеряны."])},
    "updateAvailableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите для обновления системы"])},
    "year": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("year")), " год"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])}
  },
  "panel": {
    "accounts": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счета и транзакции"])},
      "operations": {
        "columns": {
          "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата счета"])},
          "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ Счета"])},
          "operation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип операции"])},
          "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])},
        "type": {
          "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зачисление"])},
          "outcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списание"])},
          "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка"])}
        }
      }
    },
    "constructor": {
      "addType": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание типа документа"])},
        "name": {
          "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать латиницу без пробелов"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование типа документа"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить тип документа"])}
      },
      "createTemplate": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон"])}
      },
      "editTemplate": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать шаблон"])}
      },
      "filters": {
        "filter": {
          "executive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнительное"])},
          "judicial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебное"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип шаблона"])},
          "pretrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Досудебное"])},
          "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты"])}
        }
      },
      "settings": {
        "addType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый тип документа"])},
        "court": {
          "court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование суда"])},
          "decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебное решение"])},
          "shareholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебное решение (дольщики)"])}
        },
        "defaultDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы по умолчанию"])},
        "isUpdatingToastMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранение шаблона..."])},
        "magistrateCourt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы для участка мирового судьи"])},
        "regionalCourt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы для участка районного суда"])},
        "selectTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите шаблон"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конструктор документов"])}
      },
      "tab": {
        "createTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон"])},
        "editTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать шаблон"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
        "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])}
      },
      "template": {
        "copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Копия ", _interpolate(_named("name"))])},
        "createSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон"])},
        "module": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип производства"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите модуль документа"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название"])}
        },
        "toasts": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон сохранен"])}
        },
        "type": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип документа"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите вид документа"])}
        },
        "updateSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить шаблон"])}
      },
      "templates": {
        "action": {
          "remove": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить шаблон?"])}
          }
        },
        "column": {
          "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип шаблона"])},
          "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона"])},
          "production_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип модуля"])},
          "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновления"])}
        },
        "listTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список шаблонов"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите готовый документ для редактирования"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конструктор\n документов"])}
    },
    "index": {
      "balance": {
        "date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["на ", _interpolate(_named("date"))])},
        "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчетная сумма расходов\n на текущий месяц"])},
        "paidUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнить баланс"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])}
      },
      "common": {
        "field": {
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
          "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер и дата договора"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "emailBuh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email бухгалтерии"])},
          "fio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации"])},
          "registrationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата регистрации"])},
          "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часовой пояс"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие данные клиента"])}
      },
      "contracts": {
        "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор-оферта\n на оказание услуг"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор"])}
      },
      "requisites": {
        "field": {
          "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИК"])},
          "email_buh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail бухгалтера"])},
          "full_name_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование банка"])},
          "inn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
          "kor_schet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондентский счет"])},
          "kpp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КПП"])},
          "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридический адрес"])},
          "name_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное наименование"])},
          "name_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сокращенное наименование"])},
          "ogrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОГРН"])},
          "ogrn_data_reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата договора"])},
          "physical_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Физический адрес"])},
          "postal_address_zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый адрес с индексом"])},
          "ras_schet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчетный счет"])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие данные клиента"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные и реквизиты в договоре"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие данные"])}
    },
    "password": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
      "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий пароль"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
      "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль изменен"])},
      "passwordDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль"])}
    },
    "recognize": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распознавание\nдокументов"])}
    },
    "referral": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнерская ссылка"])}
    },
    "tariffs": {
      "all_tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все тарифы"])},
      "cell": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во"])},
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])}
      },
      "column": {
        "auto_renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автопродление"])},
        "debtors_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество / остаток активных должников"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
        "is_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкл/Выкл"])},
        "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество / остаток"])},
        "month_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость в месяц"])},
        "per_debtor_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость за должника"])},
        "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуга"])},
        "tariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифный план"])},
        "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого"])}
      },
      "content": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключенные услуги"])}
      },
      "current_tariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключенный тариф"])},
      "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не подключено"])},
      "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключено"])},
      "name_hint": {
        "ENRICHMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для активации и использования данной услуги требуется приобретение одного из тарифов:\n1. Формирование и отправка ЗВСП (ЭЦП)\n2. Формирование ЗВСП"])}
      },
      "productionType": {
        "executive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнительное производство"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие услуги"])},
        "judicial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебное производство"])},
        "pretrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Досудебное производство"])}
      },
      "summaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость услуг в месяц:"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифы и услуги"])},
      "total_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого в месяц"])}
    }
  },
  "permissions": {
    "production_type": {
      "account_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счета и транзакции"])},
      "executive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнительное производство"])},
      "general_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие данные"])},
      "integration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен данными - Интеграции"])},
      "judicial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебное производство"])},
      "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организации"])},
      "pretrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Досудебное производство"])},
      "rate_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифы и услуги"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен данными - Выгрузка"])}
    },
    "rights": {
      "has": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтение"])},
      "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтение/запись"])}
    }
  },
  "pretrial": {
    "sms": {
      "notSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не отправлено"])},
      "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено"])}
    },
    "voice": {
      "notSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не отправлено"])},
      "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено"])}
    }
  },
  "print": {
    "titles": {
      "judicial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите документ для печати"])},
      "executive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите документ для печати или отправки"])}
    },
    "courtComplaintOverdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок подачи жалобы"])},
    "dialog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с документами"])}
    },
    "documents": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка печати"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список документов в приложении"])}
    },
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для печати"])},
    "penaltyKeyRateMode": {
      "court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На день подачи в суд (сегодня)"])},
      "k_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По периодам действия ставки рефинансирования"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применять процентную ставку"])},
      "p_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На день частичной оплаты"])}
    },
    "period": {
      "appealFns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим отправки в ФНС онлайн по ЭЦП"])},
      "complaint_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление об ускоренном рассмотрении дела по ЭЦП"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С"])},
      "isOrdered": {
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обычным письмом"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказным письмом"])},
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказным письмом"])}
      },
      "moratorium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мораторий расчета пени"])},
      "offline_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Печать и подача в суд"])},
      "scan_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканированные документы (ЭЦП)"])},
      "sendDraftCourtOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить проект судебного приказа в суд"])},
      "sendPochta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить Почтой России"])},
      "test_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестовый режим отправки в суд онлайн по ЭЦП"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период расчета"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По"])},
      "whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За весь период"])},
      "without_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявитель освобожден от уплаты пошлины в соответствии со ст. 333,35, 333,36, 333,37 НК РФ"])}
    },
    "returnDuplicatePaymentsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат дубля госпошлины"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор услуги"])},
    "signAndSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписать и отправить\n по ЭЦП"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формирование и печать"])},
    "submitEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить на email"])},
    "submitEnforcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписать и отправить\n запрос"])},
    "submitPochta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить по почте"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с печатной формой"])},
    "toast": {
      "canNotFetchSavedCertificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить сохраненные на сервере данные о сертификатах"])},
      "canNotSaveLocalCertificateDataToServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не удалось сохранить данные сертификата на сервер"])},
      "choseComplaintLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите срок подачи жалобы"])},
      "enforcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос отправлен!"])},
      "enforcementsFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка отправки запроса"])},
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка формирования документов"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формирование данных, ожидайте..."])},
      "messageRemaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Осталось до завершения: ", _interpolate(_named("remainingTime"))])},
      "noAttorneyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите доверенность"])},
      "noAttorneySelectedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите доверенность в списке документов"])},
      "noEnforcementsApplications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка загрузки запросов в ФССП"])},
      "noGosuslugiLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка подписи по ЭЦП"])},
      "noGosuslugiMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не авторизованы на Госуслугах"])},
      "noLocalSignLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка локального подписания документов"])},
      "noLocalSignMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертификат не выбран"])},
      "noPochtaLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка отправки по Почте России"])},
      "noPochtaMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставьте учетные данные"])},
      "progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("current")), " из ", _interpolate(_named("max"))])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ готов!"])}
    },
    "type": {
      "bank_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос в банк о взыскании и аресте счета"])},
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исковое заявление"])},
      "compaint_statetax_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление о выдаче справки на возврат госпошлины (ЗВСП возвращено)"])},
      "complaint_art_331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частная жалоба (ст. 331 ГПК РФ)"])},
      "complaint_art_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление об ускорении рассмотрения дела (ст. 6.1 ГПК РФ)"])},
      "complaint_self_submission_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалоба по возвращенному определению на несоблюдение требований суда на самостоятельное направление ходатайств согласно закону"])},
      "court_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление о вынесении судебного приказа \\ Искового заявления"])},
      "fns_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос в ФНС о счетах"])},
      "fssp_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление в ФСПП"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
      "petition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ходатайство (заявление)"])},
      "petition_bailiff_court_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ходатайство о направлении судебного приказа на исполнение в службу судебных приставов в электронном виде"])},
      "petition_cancellation_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос о направлении копии определения об отмене"])},
      "petition_return_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ходатайство о возвращении заявления о выдаче судебного приказа"])},
      "petition_without_case_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявление о выдаче справки на возврат госпошлины (ЗВСП не было подано)"])}
    },
    "useSignatureAndStamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать факсимильную подпись и печать"])}
  },
  "productionType": {
    "executive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнительное производство"])},
    "judicial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Судебное производство"])},
    "pretrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Досудебное производство"])}
  },
  "pure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("message"))])},
  "pureLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("label"))])},
  "recognition": {
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для распознавания загруженных документов"])}
  },
  "request": {
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ошибка выполнения запроса (", _interpolate(_named("code")), ")"])}
  },
  "setOfCharges": {
    "dialog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с документами"])}
    },
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для формирования свода начислений"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр и печать"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с печатной формой"])},
    "toast": {
      "failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка формирования документов"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формирование свода начислений..."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ готов!"])}
    }
  },
  "sidebar": {
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© ", _interpolate(_named("name")), " ", _interpolate(_named("years"))])},
    "menu": {
      "constructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конструктор\n документов"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочий стол"])},
      "debtors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа с\n должниками"])},
      "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен\n данными"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конструктор\n отчетности"])},
      "organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организации"])},
      "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панель\n управления"])},
      "recognize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распознавание\n документов"])}
    },
    "version": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["v.", _interpolate(_named("version"))])}
  },
  "sign": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "confirm": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить повторно"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
      "subTitle": {
        "email": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["почту ", _interpolate(_named("email"))])},
        "phone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["номер ", _interpolate(_named("phone"))])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Мы отправили вам код подтверждения на\n ", _interpolate(_named("data"))])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение"])},
      "wrong": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный email?"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный номер?"])}
      }
    },
    "forgot": {
      "captcha": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код с картинки"])}
      },
      "login": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите почту или номер телефона"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес электронной почты или\n телефона, который вы использовали\n при регистрации, и мы отправим вам инструкции по восстановлению пароля."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])}
    },
    "in": {
      "agreement": {
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["политику конфиденциальности"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["соглашаюсь на обработку персональных данных\n и принимаю ", _interpolate(_named("policy"))])}
      },
      "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демо Вход"])},
      "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
      "login": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите почту или номер телефона"])}
      },
      "password": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Войдите в ", _interpolate(_named("name"))])}
    },
    "links": {
      "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользовательское соглашение"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])}
    },
    "password": {
      "password": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])}
      },
      "passwordConfirmation": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установите пароль"])}
    },
    "tabs": {
      "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
      "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать\n    на платформу по автоматизации взыскания задолженностей"])},
    "up": {
      "agreement": {
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["политику конфиденциальности"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["соглашаюсь на обработку персональных данных\n и принимаю ", _interpolate(_named("policy"))])}
      },
      "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демо Вход"])},
      "inn": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ИНН"])}
      },
      "login": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите почту или номер телефона"])}
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединяйтесь!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы с нами впервые?"])}
    }
  },
  "socket": {
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка подключения, пробуем ещё раз"])}
  },
  "tableDisplaySettings": {
    "columns": {
      "hidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрытые/Фиксированные колонки"])},
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порядок и отображение колонок"])}
    },
    "limit": {
      "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите число от 1 до 500"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записей на странице"])}
    },
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка отображения"])}
  },
  "tasksProgress": {
    "toast": {
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус задачи неизвестен"])}
    }
  },
  "unlock": {
    "dialog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблокировать выбранных должников для отправки уведомлений?"])}
    }
  },
  "update": {
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите должников для обновления данных"])}
  }
}